<template>
<!-- <div class="outer" ref="scroll"> -->
<!-- <div class="pulldown-wrapper">
            <div v-show="beforePullDown">
                <span>下拉刷新</span>
            </div>
            <div v-show="!beforePullDown">
                <div v-show="isPullingDown">
                    <span>加载中...</span>
                </div>
                <div v-show="!isPullingDown">
                    <span>刷新成功</span>
                </div>
            </div>
        </div> -->
<div class="container">
  <div class="top-box">
    <img class="background" src="/img/main/background.png" alt="">
    <!-- <div class="top" v-if="pageType == 1">问卷调研</div> -->
    <div class="top">改进建议</div>
    <div class="bottom">
      <div class="phone">{{ username }}</div>
    </div>
    <div></div>
  </div>
  <div class="create-box" v-if="pageType == 1">
    <div v-for="(item,index) in radioList" :key="index">
      <div class="create-box__title" v-if="role != 4 && item.id == 3">
        <div>问题3：总体上，您觉得评估工具中的指标要求是否能够有效地评估托育机构的实际？</div>
      </div>
      <div class="create-box__title" v-else>
        <div>{{item.name}}</div>
      </div>
      <div v-if="index < 3" class="create-box__title">
        <el-radio v-model="item.option" label="1"><span>A 是</span></el-radio>
        <el-radio v-model="item.option" label="2"><span>B 否</span></el-radio>
      </div>
      <template v-else>
        <el-input rows="2" placeholder="输入内容" v-model="item.option" maxlength="200" show-word-limit>
        </el-input>
      </template>
    </div>
  </div>
  <div class="create-box" v-else>
    <div class="create-box__title">
      <div>改进建议</div>
      <el-button style="height: 24px;font-size: 12px; margin-left: 10px;" @click="addDomain" size="mini">新增建议</el-button>
    </div>
    <el-input style="margin-bottom: 20px" v-for="(content, index) in upForm.content" :key="index" :prop="'content.' + index + '.value'" rows="2" placeholder="输入内容" v-model="content.value" maxlength="200" show-word-limit>
    </el-input>
  </div>
  <div class="btn-box" style="flex-direction: column">
    <el-button class="login-btn" :disabled="disabled" @click="feedback" v-if="pageType == 1" type="primary" v-throttle>提交
    </el-button>
    <el-button class="login-btn" @click="saveUpClick" v-if="pageType == 2" type="primary" v-throttle>提交
    </el-button>
    <!-- <div class="footer">北京京学科技发展集团有限公司提供技术支持</div> -->
  </div>
  <!-- <div class="model-box" v-if="showModel">
            <div class="select-box">
                <div class="select-box__top">是否继续自评？</div>
                <div class="select-box__bottom">
                    <div class="select-box__bottom--left">重新自测</div>
                    <div class="select-box__bottom--mid"></div>
                    <div class="select-box__bottom--right">继续</div>
                </div>
            </div>
        </div>
        <div class="model" v-if="showModel"></div> -->
</div>
<!-- </div> -->
</template>

<script>
export default {
  name: "Feedback",
  data() {
    return {
      pageType: '',
      inspect_id: '',
      canAdd: true,
      role: '',
      name: '',
      username: '',
      inspectList: [],
      orgId: '',
      explain: '',
      upForm: {
        content: [{
          value: ''
        }],
      },
      radioList: [{
        questionnaire_id: 1,
        name: '问题1：总体上，您觉得这个评估软件是否使用方便？',
        option: ''
      }, {
        questionnaire_id: 2,
        name: '问题2：总体上，您觉得评估工具中指标要求的表述是否清楚、便于理解？',
        option: ''
      }, {
        questionnaire_id: 3,
        name: '问题3：总体上，您觉得评估工具中的指标要求对于提高托育质量是否有帮助？',
        option: ''
      }, {
        questionnaire_id: 4,
        name: '问题4：您对评估工具中的指标要求和评估软件的完善，有什么改进建议？',
        option: ''
      }, {
        questionnaire_id: 5,
        name: '问题5：目前，托育机构在办托过程中，您认为还有哪些困难或问题需要解决？',
        option: ''
      }],
      suggestion: ''
    };
  },
  computed: {
    disabled() {
      return !(this.radioList[0].option && this.radioList[1].option && this.radioList[2].option && this.radioList[3].option && this.radioList[4].option)
    }
  },
  watch: {
    orgId(newId, oldId) {
      if (newId != '') {
        this.canAdd = false
      }
    }
  },
  components: {

  },
  mounted() {
    this.inspect_id = this.$route.query.id
    this.pageType = this.$route.query.type
    this.name = localStorage.getItem('name')
    this.username = localStorage.getItem('username')
    this.role = localStorage.getItem('role')
  },
  methods: {
    addDomain() {
      this.upForm.content.push({
        value: '',
      });
    },
    async feedback() {
      console.log(this.radioList)
      let res = await this.$api({
        method: 'post',
        url: 'api/inspect/questionnaire_save',
        data: {
          inspect_id: this.inspect_id,
          result_json: JSON.stringify(this.radioList)
        }
      })
      this.$router.go(-1)
    },
    saveUpClick() {
      let list = this.upForm.content.filter(e => {
        return e.value != ''
      })
      if (list.length == 0) {
        this.$Toast('请输入内容')
      } else {
        this.$Dialog.confirm({
            message: `提交后不可修改，确定提交吗`,
            confirmButtonColor: '#0083F5',
            confirmButtonText: '确定',
          })
          .then(async () => {
            this.saveUp();
          })
          .catch(() => {
            // on cancel
          });
      }
    },
    async saveUp() {
      let list = this.upForm.content.filter(e => {
        if (e.value) {
          return e
        }
      })
      let json = list.map(e => {
        if (e.value) {
          return {
            content: e.value
          }
        }
      })
      let res = await this.$api({
        method: "post",
        url: "/api/inspect/opinion_save",
        data: {
          inspect_id: this.inspect_id,
          contents_json: JSON.stringify(json)
        }
      });
      console.log(res);
      if (res.code == 0) {
        this.$router.go(-1)
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
  },
};
</script>

<style lang="scss">
.container {
  height: 100%;

  .save-question {
    width: 100px;
    height: 30px;
    font-size: 14px;
    float: left;
    margin: 20px auto;
  }

  .top-box {
    position: fixed;
    z-index: 2;
    top: 0;
    width: 100%;
    height: 125px;
    // background-image: url('/img/main/background.png');
    // background-size: 100% 125px;

    .background {
      position: absolute;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 125px;
    }

    .top {
      margin: 25px 0 0 15px;
      text-align: left;
      font-size: 18px;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 27px;
    }

    .bottom {
      z-index: 99;
      margin: 4px 12px 0 15px;
      display: flex;
      align-items: center;

      .phone {
        margin-right: auto;
        font-size: 14px;
        font-weight: 400;
        color: #FFF;
        line-height: 20px;
      }

      .guide {
        font-size: 13px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 20px;
        background-clip: text;
      }

      .icon {
        margin-left: 3px;
        width: 13px;
        height: 13px;
      }
    }
  }

  .create-box {
    position: absolute;
    top: 100px;
    z-index: 99;
    height: calc(100% - 100px);
    width: 100%;
    overflow-y: scroll;
    background: #F7F8FA;
    border-radius: 10px 10px 0px 0px;
    padding: 0 15px 100px 15px;

    .create-box__title {
      margin-top: 15px;
      margin-bottom: 15px;
      display: flex;
      text-align: left;
      align-items: center;
      font-size: 15px;
      font-weight: 500;
      color: #333333;
      line-height: 24px;
    }

    .el-select {
      margin-top: 15px;
      width: 100%;

      .el-input__inner {
        width: 100%;
        background: #fff;
      }
    }

    .el-textarea {
      margin-top: 15px;

      textarea::placeholder {
        font-size: 17px;
        font-weight: 400;
        color: #B0B3BC;
        line-height: 26px;
      }

      .el-textarea__inner {
        border: none;
        min-height: 114px !important;
      }
    }
  }

  .btn-box {
    z-index: 100;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 73px;
    background: #FFFFFF;
    box-shadow: 0px -1px 4px 0px rgba(242, 242, 242, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;

    .login-btn {
      width: 323px;
      font-size: 19px;
      color: #ffffff;
    }
  }

}
</style>
